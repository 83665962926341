@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 9px;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgb(3, 2, 44);
  overflow: hidden;
}

.box {
  position: relative;
  width: 600px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box h2 {
  color: #fff;
  font-size: 5em;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-shadow: 0 0 10px #00b3ff,
    0 0 20px #00b3ff,
    0 0 40px #00b3ff,
    0 0 80px #00b3ff,
    0 0 120px #00b3ff;
}

.lightbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  border-radius: 10px;
  background: #fff;
  z-index: 2;
  box-shadow: 0 0 10px #00b3ff,
    0 0 20px #00b3ff,
    0 0 40px #00b3ff,
    0 0 80px #00b3ff,
    0 0 120px #00b3ff;
  animation: animatelightbar 5s linear infinite;
}

@keyframes animatelightbar {

  0%,
  5% {
    transform: scaleY(0) translateX(0);
  }

  10% {
    transform: scale(1) translateX(0);
  }

  90% {
    transform: scaleY(1) translateX(calc(600px - 10px));
  }

  95%,
  100% {
    transform: scaleY(0) translateX(calc(600px - 10px));
  }
}

.toplayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(3, 2, 44);
  animation: animatetoplayer 10s linear infinite;
}

@keyframes animatetoplayer {

  0%,
  2.5% {
    transform: translateX(0);
  }

  5% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(100%);
  }

  47.5%,
  50% {
    transform: translateX(100%);
  }

  50.001%,
  52.5% {
    transform: translateX(-100%);
  }

  55% {
    transform: translateX(-100%);
  }

  95% {
    transform: translateX(0%);

  }

  97.5%,
  100% {
    transform: translateX(0%);
  }
}


.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin-top: 100px;

  color: white;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #78f8ec;
  text-shadow: 0 0 10px #1b666a, 0 0 20px #7df9ff, 0 0 30px #7df9ff, 0 0 40px #0fa, 0 0 70px #0fa, 0 0 80px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
}

button {
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px;
  border: 2px solid #fff;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: transparent;
  color: #fff;
}

button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px #7df9ff;
}

button.yes-button {
  border-color: #4caf50;
  background-color: rgb(60, 165, 60);
}

button.no-button {
  border-color: #f44336;
  animation: none;
}

@keyframes glowNoButton {

  0%,
  100% {
    box-shadow: 0 0 10px #f44336, 0 0 20px #f44336, 0 0 30px #f44336;
  }

  50% {
    box-shadow: 0 0 20px #f44336, 0 0 30px #f44336, 0 0 40px #f44336;
  }
}

button.no-button:hover {
  animation: glowNoButton 1.5s infinite alternate;
}


@media (max-width: 600px) {
  .box {
    width: 90%;
    height: auto;
  }

  .box h2 {
    font-size: 3em;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .box {
    width: 90%;
    /* Adjust the width to fit smaller screens */
    height: auto;
    /* Make the height adjust to content */
  }

  .box h2 {
    font-size: 2em;
    /* Reduce the font size for smaller screens */
  }

  .button-container {
    flex-direction: column;
    /* Stack buttons vertically */
  }

  button {
    padding: 12px 24px;
    /* Increase touch target size */
    margin: 5px 0;
    /* Add some space between vertically stacked buttons */
    font-size: 16px;
    /* Adjust font size for better readability */
  }

  
}